const Data=[
{
  id:1,
  name:"Hammock photo",
  info:"Tote bag sartorial mlkshk air plant vinyl banjo lumbersexual poke leggings offal cold-pressed brunch neutra. Hammock photo booth live-edge disrupt. Post-ironic selvage chambray sartorial freegan meditation.",
  pic:'https://media.istockphoto.com/id/1197071216/photo/portrait-of-a-smart-and-handsome-it-specialist-wearing-glasses-smiles-behind-him-personal.jpg?s=612x612&w=0&k=20&c=Dy8TjvDmeXWhR6gAZ_OuqLu3ytUJmtycEYdVQenpWoI=',
  job:"Full Stack Web Developer"
},
{
    id:2,
    name:"Jon Bos",
    info:"chartreuse kombucha meditation, man bun four dollar toast street art cloud bread live-edge heirloom. Butcher drinking vinegar franzen authentic messenger bag copper mug food truck taxidermy. Mumblecore lomo echo park ",
    pic:'https://media.istockphoto.com/id/1175076892/photo/young-man-working-in-the-office.jpg?s=612x612&w=0&k=20&c=yngrcYjMHVSv8KqLryqFgLwJjuVhsjf1qOUgljBr23I=',
    job:"Mobile App Dev"
  },{
    id:3,
    name:"Linda Jon",
    info:"Hashtag drinking vinegar scenester mumblecore snackwave four dollar toast, lumbersexual XOXO. Cardigan church-key pabst, biodiesel vexillologist viral squid. Franzen af pitchfork, mumblecore try-hard kogi XOXO roof party",
    pic:'https://c8.alamy.com/comp/2MN2G1B/portrait-of-successful-smiling-programmer-inside-modern-green-loft-office-blond-man-smiling-and-looking-at-camera-businessman-in-sweater-and-casual-shirt-close-up-working-with-computer-2MN2G1B.jpg',
    job:"Front_end Dev"
  },{
    id:4,
    name:"Masri Alemu",
    info:"croix cardigan neutra retro tattooed copper mug. Meditation lomo biodiesel scenester Fam VHS enamel pin try-hard echo park raw denim unicorn fanny pack vape authentic. Helvetica fixie church-key, small batch jianbing messenger ",
    pic:'https://c8.alamy.com/comp/2C57EWN/confident-man-writing-code-while-sitting-in-a-massage-armchair-in-the-office-with-glass-walls-in-background-2C57EWN.jpg',
    job:"Mern Stack Dev"
  },{
    id:5,
    name:"Bob Yisml",
    info:"baby woke mumblecore stumptown enamel pin. Snackwave prism pork belly, blog vape four loko sriracha messenger bag jean shorts DIY bushwick VHS. Banjo post-ironic hella af, palo santo craft beer gluten-free. YOLO drinking ",
    pic:'https://c8.alamy.com/comp/2C57EXT/confident-man-writing-code-while-sitting-in-a-massage-armchair-in-the-office-with-glass-walls-in-background-2C57EXT.jpg',
    job:"Web Dev"
  },{
    id:6,
    name:"Tomas Alonso",
    info:"vinegar chambray pok pok selfies quinoa kinfolk pitchfork street art la croix unicorn DIY. Woke offal jianbing venmo tote bag, palo santo subway tile slow-carb post-ironic pug ugh taxidermy squid. Pour-over glossier chambray ",
    pic:'https://i.insider.com/601441dd6dfbe10018e00c25?width=700',
    job:"Java Dev"
  },
]

export default Data